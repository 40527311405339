@import 'assets/loading.css';
@import 'assets/scrollbars.css';
@import 'assets/minireset.css';
@import 'assets/fonts/roboto/index.css';
@import '~rc-table/assets/index.css';
@import 'assets/rc-table.css';
@import '~leaflet/dist/leaflet.css';
@import '~react-image-gallery/styles/css/image-gallery.css';

/* Needed to disable border on focused HotKey element */
div[tabindex='-1']:focus {
  outline: 0;
}

.tagspaces-main-color {
  color: #1dd19f;
}

html,
body {
  overscroll-behavior-x: none;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.react-split > .split-container.vertical > .splitter {
  z-index: 1;
}

.rc-table-row-expand-icon {
  width: 13px !important;
  height: 13px !important;
  line-height: 13px !important;
  margin: 5px;
  cursor: pointer;
}

#root {
  height: 100%;
}
