/* CSS Mini Reset */
html, body, div, form, fieldset, legend, label
{
 margin: 0;
 padding: 0;
}

body
{
  overflow: hidden;
  margin-top: 0;
}

table
{
 border-collapse: collapse;
 border-spacing: 0;
}

th, td
{
 text-align: left;
 vertical-align: top;
}

h1, h2, h3, h4, h5, h6, th, td, caption { font-weight:normal; }

img { border: 0; }

:not(input):not(textarea),
:not(input):not(textarea)::after,
:not(input):not(textarea)::before {
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
}
input, button, textarea, :focus {
    /* outline: none; */
}
